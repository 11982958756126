<template>
	<div class="reservation-container">
		<div class="form-section">
			<form @submit.prevent="submitForm">
				<label for="fullName">Celé meno</label>
				<input class="fullname" type="text" id="fullName" v-model="form.fullName" required />

				<label for="email">Emailová adresa</label>
				<input type="email" id="email" v-model="form.email" required />

				<label for="phone">Telefónne číslo</label>
				<input type="tel" id="phone" v-model="form.phone" required />

				<label for="address">Adresa</label>
				<input type="address" id="address" v-model="form.address" required />

				<label for="dateTime">Preferovaný dátum a čas</label>
				<div class="datetime-section">
					<div class="input-group">
						<input type="time" id="time" v-model="form.time" step="1" class="time-input" />
					</div>
					<div class="input-group">
						<input type="date" id="date" v-model="form.date" class="date-input" />
					</div>
				</div>

				<button class="submit-reservation desktop-only">ODOSLAŤ REZERVÁCIU <img alt="arrow" src="../assets/arrow.svg" /></button>
			</form>
		</div>
		<div class="map-section">
			<label for="location">Lokalita parcely - vyhľadajte alebo kliknite na mapu a zadajte bod pre presnejšiu lokáciu</label>

			<div id="map" ref="map"></div>
			<img class="search-icon" alt="search icon" src="../assets/lupa.svg" />
		</div>
		<form @submit.prevent="submitForm">

		<button class="submit-reservation mobile-only">ODOSLAŤ REZERVÁCIU <img alt="arrow" src="../assets/arrow.svg" /></button>
	</form>

		<transition name="fade">
			<div v-if="isAlertVisible" class="alert">
				<div class="alert-header">
					<h2>Rezervácia bola úspešná!</h2>
					<button @click="closeAlert">×</button>
				</div>
				<div class="alert-body">
					<p>Ďakujeme za vašu rezerváciu. Tu sú podrobnosti:</p>
					<div class="alert-details">
						<p><strong>Celé meno:</strong> {{ form.fullName }}</p>
						<p><strong>Emailová adresa:</strong> {{ form.email }}</p>
						<p><strong>Telefónne číslo:</strong> {{ form.phone }}</p>
						<p><strong>Adresa:</strong> {{ form.address }}</p>
						<p><strong>Preferovaný dátum a čas:</strong> {{ form.date }} {{ form.time }}</p>
						<p><strong>S-JTSK (JTSK03) (X, Y):</strong> {{ form.location.sjtsk }}</p>
						<p><strong>ETRS89-TM34 (E, N):</strong> {{ form.location.etrs89 }}</p>
						<p><strong>WGS84 (φ, λ):</strong> {{ form.location.wgs84 }}</p>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
//UNKOMENTNUT PRIBLIZIT A VZDIALIT
import emailjs from "emailjs-com"
import { loadModules } from "esri-loader"
import proj4 from "proj4"

// Define EPSG:5514 (S-JTSK) coordinate reference system
proj4.defs("EPSG:5514", "+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=570.69,85.69,462.84,-4.99821,-1.58676,-5.2611,3.69072 +units=m +no_defs")

// Define EPSG:3035 (ETRS89) coordinate reference system
proj4.defs("EPSG:3035", "+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 +datum=WGS84 +units=m +no_defs")

export default {
	data() {
		return {
			form: {
				fullName: "",
				email: "",
				phone: "",
				address: "",
				date: "",
				time: "",
				crop: "",
				location: {
					sjtsk: "",
					etrs89: "",
					wgs84: "",
				},
			},
			map: null,
			searchWidget: null,
			isAlertVisible: false,
		}
	},
	mounted() {
		this.initializeMap()
	},
	methods: {
		initializeMap() {
			loadModules(["esri/Map", "esri/views/MapView", "esri/widgets/Search", "esri/Graphic", "esri/layers/GraphicsLayer", "esri/geometry/support/webMercatorUtils"])
				.then(([Map, MapView, Search, Graphic, GraphicsLayer, webMercatorUtils]) => {
					const map = new Map({
						basemap: "osm", // Use OpenStreetMap basemap for more detailed view
					})

					const view = new MapView({
						container: this.$refs.map,
						map: map,
						center: [17.8785, 48.1524],
						zoom: 10,
					})

					const searchWidget = new Search({
						view: view,
					})

					const graphicsLayer = new GraphicsLayer()
					map.add(graphicsLayer)

					view.ui.add(searchWidget, {
						position: "top-right",
					})

					view.on("click", (event) => {
						this.clearSearchInput() // Clear search input on map click
						const geographicPoint = webMercatorUtils.webMercatorToGeographic(event.mapPoint)

						const wgs84Coords = [geographicPoint.x, geographicPoint.y]

						// Transform WGS84 to S-JTSK
						const sjtskCoords = proj4("EPSG:4326", "EPSG:5514", wgs84Coords).map((coord) => -coord)

						// Transform WGS84 to ETRS89
						const etrs89Coords = proj4("EPSG:4326", "EPSG:3035", wgs84Coords)

						this.form.location.sjtsk = `${sjtskCoords[1].toFixed(0)} m, ${sjtskCoords[0].toFixed(0)} m`
						this.form.location.etrs89 = `${etrs89Coords[1].toFixed(0)} m, ${etrs89Coords[0].toFixed(0)} m`
						this.form.location.wgs84 = `${geographicPoint.y.toFixed(6)}°, ${geographicPoint.x.toFixed(6)}°`

						const popupTemplate = {
							title: "Location Information",
							content: `
                            <b>S-JTSK (JTSK03) (X, Y):</b> ${this.form.location.sjtsk}<br>
                            <b>ETRS89-TM34 (E, N):</b> ${this.form.location.etrs89}<br>
                            <b>WGS84 (φ, λ):</b> ${this.form.location.wgs84}<br>
                        `,
						}

						const pointGraphic = new Graphic({
							geometry: {
								type: "point",
								longitude: geographicPoint.x,
								latitude: geographicPoint.y,
							},
							symbol: {
								type: "simple-marker",
								color: "green",
								size: "8px",
							},
							// popupTemplate: popupTemplate,
						})

						graphicsLayer.removeAll()
						graphicsLayer.add(pointGraphic)

						// view.popup.open({
						// 	title: popupTemplate.title,
						// 	content: popupTemplate.content,
						// 	location: event.mapPoint,
						// })
					})

					this.map = map
					this.searchWidget = searchWidget
				})
				.catch((err) => {
					console.error("ArcGIS: ", err)
				})
		},
		clearSearchInput() {
			this.searchQuery = ""
		},
		submitForm() {
			const templateParams = {
				CeleMeno: this.form.fullName,
				Email: this.form.email,
				TelefCislo: this.form.phone,
				Adresa: this.form.address,
				Datum: this.form.date,
				Cas: this.form.time,
				Lokacia: `
					S-JTSK (JTSK03) (X, Y): ${this.form.location.sjtsk}
					ETRS89-TM34 (E, N): ${this.form.location.etrs89}
					WGS84 (φ, λ): ${this.form.location.wgs84}
				`,
			}

			emailjs.send("service_rsir8pq", "template_ikzylgr", templateParams, "T_y0dwmCOA4DyTETq").then(
				(response) => {
					this.showAlert()
				},
				(error) => {
					console.error("Failed to send reservation:", error)
					alert("Failed to send reservation. Please try again.")
				}
			)
		},
		showAlert() {
			this.isAlertVisible = true
		},
		closeAlert() {
			this.isAlertVisible = false
		},
	},
}
</script>
<style scoped>
.reservation-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	background-color: none;
	padding: 20px;
	font-family: PP Neue Montreal;
}

.form-section {
	width: 38%;
}

.datetime-section {
	display: flex;
	justify-content: space-between;
}

.input-group {
	width: 48%;
}

label {
	display: block;
	margin-bottom: 5px;
	color: #666;
}

input.fullname,
input[type="email"],
input[type="tel"],
input[type="address"],
input[type="date"],
input[type="time"],
select {
	width: 100%;
	padding: 14px 24px;
	margin-bottom: 20px;
	border: 1px solid white;
	border-radius: 13.33px;
	background: rgb(244, 244, 244);
	box-sizing: border-box;
	font-size: 14px;
}

input[type="date"],
input[type="time"],
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: rgb(244, 244, 244);
	padding: 14px 24px;
	border: 1px solid white;
	border-radius: 13.33px;
	font-size: 14px;
}
.alert {
	z-index: 1000; /* This ensures the alert is on top */
}

.submit-reservation {
	position: static;
	transition: background-color 0.3s, transform 0.3s;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px 34px 10px 34px;
	border: none;
	border-radius: 999px;
	color: rgb(255, 255, 255);
	font-family: PP Neue Montreal;
	font-size: 16px;
	font-weight: 400;
	line-height: 140%;
	letter-spacing: 0%;
	text-align: center;
	text-transform: uppercase;
	background: rgb(103, 121, 51);
	/* Inside Auto Layout */
	flex: none;
	flex-grow: 0;
	margin: 16px 0px;
	transition: background-color 0.3s, transform 0.3s;
}
.submit-reservation img {
	margin-left: 10px;
	margin-top: auto;
	margin-bottom: auto;
}

.submit-reservation:hover {
	background-color: #6c8b3c;
	transform: scale(1.05);
}

.map-section {
	width: 60%;
	height: 600px;
	position: relative;
}
.search::placeholder {
	margin-left: 20px;
}
.search {
	border: none;
	border-radius: 13.33px;
	margin-top: 24px;
	margin-left: 34px;

	background: rgb(255, 255, 255);
	width: 350px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 14px 24px;
}

.search-container {
	position: relative;
	width: 300px;
	margin-right: 20px;
}

#map {
	width: 100%;
	height: 100%;
	border: none;
	border-radius: 15px;
	touch-action: none;

	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.submit-reservation.desktop-only {
	display: block;
}

.submit-reservation.mobile-only {
	display: none;
}
@media (min-width: 1000px) {
	.search-icon {
		position: absolute;
		top: 56px;
		left: 8px;
		width: 14px;

		margin-left: 14px;
		z-index: 10;
		height: 20px;
	}
}
@media (max-width: 1000px) {
	.search-icon {
		position: absolute;
		top: 75px;
		left: 17px;
		width: 11px;

		margin-left: 14px;
		z-index: 10;
		height: 20px;
	}
	.reservation-container {
		flex-direction: column;
	}
	.form-section {
		width: 100%;
	}
	.map-section {
		width: 100%;
		margin-top: 20px;
		position: relative;
	}
	.submit-reservation.desktop-only {
		display: none;
	}
	.submit-reservation.mobile-only {
		display: flex;
		margin-top: 80px;
		width: auto;
		text-align: center;
		justify-content: flex-start;
		align-self: flex-start;
	}
}
@media (min-width: 1000px) {
	.alert {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		color: #333;
		padding: 20px;
		border-radius: 10px;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
		z-index: 1000;
		width: 50%;
		height: 50%;
		opacity: 1;
		transition: opacity 0.5s ease;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}

	.alert-header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #ddd;
		padding-bottom: 10px;
		margin-bottom: 20px;
	}

	.alert-header h2 {
		margin: 0;
		font-size: 24px;
		color: #67a51e;
	}

	.alert-header button {
		background: none;
		border: none;
		color: #666;
		margin-left: 15px;
		font-size: 44px;
		cursor: pointer;
	}

	.alert-body {
		width: 100%;
		flex-grow: 1;
	}

	.alert-body p {
		margin: 0 0 20px;
		font-size: 16px;
		color: #555;
	}

	.alert-details {
		font-size: 14px;
		color: #777;
	}

	.alert-details p {
		margin: 5px 0;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s;
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
}

@media (max-width: 1000px) {
	.alert {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		color: #333;
		padding: 20px;
		border-radius: 10px;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
		z-index: 1000;
		width: 70%; /* Mobile alert width */
		height: auto; /* Ensure alert adapts to content */
		opacity: 1;
		transition: opacity 0.5s ease;
		display: flex; /* Ensure the alert is displayed */
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}

	.alert-header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #ddd;
		padding-bottom: 10px;
		margin-bottom: 20px;
	}

	.alert-header h2 {
		margin: 0;
		font-size: 24px;
		color: #67a51e;
	}

	.alert-header button {
		background: none;
		border: none;
		color: #666;
		margin-left: 15px;
		font-size: 44px;
		cursor: pointer;
	}

	.alert-body {
		width: 100%;
		flex-grow: 1;
	}

	.alert-body p {
		margin: 0 0 20px;
		font-size: 16px;
		color: #555;
	}

	.alert-details {
		font-size: 14px;
		color: #777;
	}

	.alert-details p {
		margin: 5px 0;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s;
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
}
</style>
<style>
@media (min-width: 1000px) {
	.esri-search__input {
		border: none;
		border-radius: 13.33px;
		padding: 14px 34px;
		margin: 10px;
		background: rgb(255, 255, 255);
		top: 44px;
		left: 24px;
		width: 350px;
		font-size: 14px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	}
}
@media (max-width: 1000px) {
	.esri-search__input {
		border: none;
		border-radius: 13.33px;
		padding: 14px 30px;

		margin: 30px 20px;
		background: rgb(255, 255, 255);
		top: 44px;
		left: 24px;
		width: 200px;
		font-size: 12px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	}
	/* .esri-search__input[title="Find address or place"] {
		display: none;
	}
	.esri-search__input[title="Find address or place"]::after {
		content: "Nájsť adresu alebo miesto";
	} */
}
.esri-search__submit-button {
	display: none;
}

.esri-search__clear-button {
	padding: 20px;
}
.esri-widget--button[title="Priblížiť"] .esri-icon-font-fallback-text {
	display: none; /* Hide the original text */
}
.esri-widget--button[title="Zoom in"] .esri-icon-font-fallback-text {
	display: none; /* Hide the original text */
}
.esri-widget--button[title="Zoom in"]::after {
	content: "+"; /* Insert plus symbol */
	font-size: 24px;
	color: black;
}

.esri-widget--button[title="Priblížiť"]::after {
	content: "+"; /* Insert plus symbol */
	font-size: 24px;
	color: black;
}

/* Target the zoom out button */
.esri-widget--button[title="Oddialiť"] .esri-icon-font-fallback-text {
	display: none; /* Hide the original text */
}
/* Target the zoom out button */
.esri-widget--button[title="Zoom out"] .esri-icon-font-fallback-text {
	display: none; /* Hide the original text */
}
.esri-widget--button[title="Zoom out"]::after {
	content: "-"; /* Insert minus symbol */
	font-size: 24px;
	color: black;
}

.esri-widget--button[title="Oddialiť"]::after {
	content: "-"; /* Insert minus symbol */
	font-size: 24px;
	color: black;
}

/* General button styling */
.esri-widget--button {
	width: 40px;
	height: 40px;
	background: white;

	border: none;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 5px;
	transition: background 0.3s, transform 0.3s;
}
.esri-popup__main-container {
	display: none;
}
.esri-search__submit-button {
	display: none;
}
.esri-zoom {
	position: absolute;
	bottom: 0px;
	right: 30px;
	display: flex;
	flex-direction: column;
}
.esri-widget--button:hover {
	background: #f0f0f0;
	transform: scale(1.05);
}
.esri-search__clear-button {
	display: none;
}
.esri-widget--button:active {
	transform: scale(0.95);
}

.esri-search--show-suggestions,
.esri-search__container {
	position: absolute;
	top: 34px;
	font-weight: 500;
}
.esri-search__warning-body {
	margin-left: 20px;
}
/* Style the map container */
.esri-view-root {
	border-radius: 15px;
	overflow: hidden;
}
.esri-attribution__sources,
.esri-attribution__powered-by,
.esri-search__no-value-text {
	display: none;
}

.esri-popup .esri-popup__content {
	font-family: PP Neue Montreal;
}
</style>
