<template>
	<section class="advantages">
		<div class="advantages-grid">
			<div class="advantage-item title">
				<h>
					Výhody<br />
					<span>postreku dronom</span>
				</h>
			</div>
			<div class="advantage-item" v-for="(advantage, index) in advantages" :key="index" :class="'item-' + index">
				<h3>{{ advantage.title }}</h3>
				<p>{{ advantage.description }}</p>
			</div>
			<div class="advantage-item reservation">
				<button @click="scrollToReservation" class="submit-reservation">ODOSLAŤ REZERVÁCIU <img alt="arrow" src="../assets/arrow.svg" /></button>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	data() {
		return {
			advantages: [
				{ title: "KONTROLA", description: "Ovláda sa pomocou diaľkového ovládača alebo počítača" },
				{ title: "POSTREK DRONOM", description: "Používa bezpilotné lietadlá (drony)" },
				{ title: "FLEXIBILITA", description: "Ponúka väčšiu presnosť a flexibilitu" },
				{ title: "RIZIKO OPERÁTORA", description: "Menšie riziko pre operátora vďaka fyzickému letu" },
				{ title: "EFEKTÍVNOSŤ", description: "Efektívnejší pre menšie plochy alebo ťažko prístupné miesta" },
			],
		}
	},
	methods: {
		scrollToReservation() {
			const reservationSection = document.getElementById("reservation")
			if (reservationSection) {
				reservationSection.scrollIntoView({ behavior: "smooth" })
			}
		},
	},
}
</script>

<style scoped>
* {
	font-family: "PP Neue Montreal";
}

.advantages {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 40px 0;
	padding: 20px;
}

.content {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
}
.advantage-item h2 {
	text-align: start;
}

.advantages-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, auto);
	width: 100%;
}
.advantage-item.title {
	border: none;
	padding: 0;
	margin: 0;

	font-family: PP Neue Montreal;
	font-size: 56px;
	font-weight: 500;
	line-height: 110%;
	letter-spacing: 0%;
	display: flex;
	justify-content: left;

	align-items: center;

}
.advantage-item.title span {
	border: none;
	padding: 0;
	margin: 0;
	font-family: PP Neue Montreal;
	font-size: 56px;
	font-weight: 500;
	line-height: 110%;
	letter-spacing: 0%;
	color: rgb(103, 121, 51);
	text-align: left;
}
.advantage-item {
	border: 1px solid rgb(234, 234, 234);
	border-radius: 20px;

	padding: 60px;
	box-sizing: border-box;
	text-align: start;
}
.advantage-item h3 {
	color: rgb(21, 21, 21);
	font-family: PP Neue Montreal;
	font-size: 48px;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: 0%;
	text-align: left;
	text-transform: uppercase;
}

.advantage-item p {
	color: rgba(21, 21, 21, 0.4);
	font-family: PP Neue Montreal;
	font-size: 20px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -2%;
	text-align: left;
}

.advantage-item:nth-child(1) {
	grid-column: 1 / span 2;
	grid-row: 1;
}

.advantage-item:nth-child(2) {
	grid-column: 3;
	grid-row: 1 / span 1;
}

.advantage-item:nth-child(3) {
	grid-column: 1;
	grid-row: 2;
}

.advantage-item:nth-child(4) {
	grid-column: 2;
	grid-row: 2;
}

.advantage-item:nth-child(5) {
	grid-column: 2 / span 1;
	grid-row: 4;
}

.reservation {
	grid-column: 1 / span 1;
	grid-row: 4;
	display: flex;
	justify-content: center;

	align-items: center;
}

.submit-reservation {
	position: static;
	transition: background-color 0.3s, transform 0.3s;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px 34px 10px 34px;
	border: none;
	border-radius: 999px;
	color: rgb(255, 255, 255);
	font-family: PP Neue Montreal;
	font-size: 16px;
	font-weight: 400;
	line-height: 140%;
	letter-spacing: 0%;
	text-align: center;
	text-transform: uppercase;
	background: rgb(103, 121, 51);
	/* Inside Auto Layout */
	flex: none;
	flex-grow: 0;
	margin: 16px 0px;
	transition: background-color 0.3s, transform 0.3s;
}
.submit-reservation img {
	margin-left: 10px;
	margin-top: auto;
	margin-bottom: auto;
}

.submit-reservation:hover {
	background-color: #6c8b3c;
	transform: scale(1.05);
}

@media (max-width: 1224px) {
	.advantages-grid {
		grid-template-columns: 1fr 1fr;
	}

	.advantage-item:nth-child(1) {
		grid-column: 1 / span 2;
	}

	.advantage-item:nth-child(2) {
		grid-column: 1;
		grid-row: 3;
	}

	.advantage-item:nth-child(3) {
		grid-column: 2;
		grid-row: 2;
	}

	.advantage-item:nth-child(4) {
		grid-column: 1;
		grid-row: 4;
	}

	.advantage-item:nth-child(5) {
		grid-column: 2;
		grid-row: 6;
	}

	.reservation {
		grid-column: 1 / span 2;
		grid-row: 7;
	}

	.advantage-item.title {
		border: none;
		padding: 0;
		margin: 0;
		font-family: PP Neue Montreal;
		margin-bottom: 36px;
		font-family: PP Neue Montreal;
		font-size: 32px;
		font-weight: 500;
		line-height: 110%;
		justify-content: center;

		letter-spacing: 0%;
		text-align: center;
	}
	.advantage-item.title span {
		border: none;
		padding: 0;
		margin: 0;
		font-family: PP Neue Montreal;
		font-size: 32px;
		font-weight: 500;
		line-height: 110%;
		letter-spacing: 0%;
		color: rgb(103, 121, 51);
		text-align: left;
	}
}

@media (max-width: 1300px) {
	.advantages-grid {
		grid-template-columns: 1fr 1fr;
	}

	.advantage-item:nth-child(1) {
		grid-column: 1 / span 2;
	}

	.advantage-item:nth-child(2) {
		grid-column: 1/ span 2;
		grid-row: 3 ;
	}

	.advantage-item:nth-child(3) {
		grid-column: 1 / span 2;
		grid-row: 2;
	}

	.advantage-item:nth-child(4) {
		grid-column: 1;
		grid-row: 4;
	}

	.advantage-item:nth-child(5) {
		grid-column: 1 / span 2;
		grid-row: 6;
	}

	.reservation {
		grid-column: 1 / span 2;
		grid-row: 7;
	}
	.advantage-item h3 {
		color: rgb(21, 21, 21);
		font-family: PP Neue Montreal;
		font-size: 32px;
		font-weight: 400;
		line-height: 100%;
		letter-spacing: 0%;
		text-align: left;
	}
	.advantage-item p {
		color: rgba(21, 21, 21, 0.4);
		font-family: PP Neue Montreal;
		font-size: 20px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -2%;
		text-align: left;
	}

	.advantage-item.title {
		border: none;
		padding: 0;
		margin: 0;
		font-family: PP Neue Montreal;
		margin-bottom: 36px;
		font-family: PP Neue Montreal;
		font-size: 32px;
		font-weight: 500;
		line-height: 110%;
		justify-content: center;

		letter-spacing: 0%;
		text-align: center;
	
	}
	.advantage-item.title span {
		border: none;
		padding: 0;
		margin: 0;
		font-family: PP Neue Montreal;
		font-size: 32px;
		font-weight: 500;
		line-height: 110%;
		letter-spacing: 0%;
		color: rgb(103, 121, 51);
		text-align: left;
	}
}
@media (max-width: 1000px) {
	.advantages-header h2 {
		font-size: 28px;
	}

	.advantage-item h3 {
		color: rgb(21, 21, 21);
		font-family: PP Neue Montreal;
		font-size: 32px;
		font-weight: 400;
		line-height: 100%;
		letter-spacing: 0%;
		text-align: left;
	}
	.advantage-item p {
		color: rgba(21, 21, 21, 0.4);
		font-family: PP Neue Montreal;
		font-size: 20px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -2%;
		text-align: left;
	}

	.submit-reservation {
		font-size: 14px;
		padding: 8px 24px;
	}

	.advantages-grid {
		grid-template-columns: 1fr;
	}

	.advantage-item:nth-child(1),
	.advantage-item:nth-child(2),
	.advantage-item:nth-child(3),
	.advantage-item:nth-child(4),
	.advantage-item:nth-child(5) {
		grid-column: 1;
	}

	.reservation {
		grid-column: 1;
	}
}
</style>
