<template>
	<div class="bottom-nav" :class="{ 'hidden-on-desktop': !isVisible && !isMobile, 'show-dropdown': showDropdown }">
		<div class="nav-container">
			<div class="logo">
				<a href=""> <img class="logoImg" src="../assets/file.png" alt="Dronik Logo" /></a>
				<a href=""> <img class="sagropImg" src="../assets/sagropxdronik.png" alt="Dronik Logo" /></a>
			</div>
			<div class="nav-links">
				<div v-for="link in links" :key="link.id" class="nav-item" :class="{ active: activeLink === link.id }" @click="setActiveLink(link.id)">
					{{ link.label }}
				</div>
			</div>
			<button class="reserve-button" @click="scrollToReservation">Rezervovať <img alt="arrow" src="../assets/arrow-black.svg" /></button>
			<div class="dropdown-toggle" @click="toggleDropdown">
				<div class="menu-icon" :class="{ open: showDropdown }">
					<span :class="{ 'white-span': showDropdown }"></span>
					<span :class="{ 'white-span': showDropdown }"></span>
					<span :class="{ 'white-span': showDropdown }"></span>
				</div>
			</div>
		</div>
		<div class="dropdown-menu" v-if="showDropdown">
			<div class="blur-bg"></div>
			<div class="menu-content">
				<div v-for="link in links" :key="link.id" class="dropdown-item" :class="{ active: activeLink === link.id }" @click="setActiveLink(link.id)">
					{{ link.label }}
				</div>
				<button @click="scrollToReservation(true)" class="reserve-button-dropdown">Rezervovať <img alt="arrow" src="../assets/arrow.svg" /></button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			activeLink: null,
			showDropdown: false,
			links: [
				{ id: 1, label: "Domov", sectionId: "home" },
				{ id: 2, label: "Rezervácia", sectionId: "reservation" },
				{ id: 3, label: "O nás", sectionId: "about" },
				{ id: 4, label: "Oprávnenia", sectionId: "permissions" },
				{ id: 5, label: "FAQ", sectionId: "faq" },
				{ id: 6, label: "Kontakt", sectionId: "contact" },
			],
			lastScrollTop: 0,
			isVisible: true,
			isMobile: window.innerWidth <= 1205,
		}
	},
	methods: {
		setActiveLink(linkId) {
			this.activeLink = linkId
			const link = this.links.find((link) => link.id === linkId)
			const section = document.getElementById(link.sectionId)
			if (section) {
				section.scrollIntoView({ behavior: "smooth" })
			}
			this.showDropdown = false // Close dropdown after selection
		},
		scrollToReservation(closeDropdown = false) {
			const reservationSection = document.getElementById("reservation")
			if (reservationSection) {
				reservationSection.scrollIntoView({ behavior: "smooth" })
			}
			if (closeDropdown) {
				this.showDropdown = false
			}
		},
		handleScroll() {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop
			if (scrollTop > this.lastScrollTop) {
				// Scrolling down
				this.isVisible = false
			} else {
				// Scrolling up
				this.isVisible = true
			}
			this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop // For Mobile or negative scrolling
		},
		toggleDropdown() {
			this.showDropdown = !this.showDropdown
		},
		updateIsMobile() {
			this.isMobile = window.innerWidth <= 1205
		},
		checkSectionInView() {
			const sections = this.links.map((link) => document.getElementById(link.sectionId))
			const observerOptions = {
				root: null,
				threshold: 0.6, // Adjust as needed
			}
			const observerCallback = (entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						const activeLink = this.links.find((link) => link.sectionId === entry.target.id)
						this.activeLink = activeLink ? activeLink.id : null
					}
				})
			}
			const observer = new IntersectionObserver(observerCallback, observerOptions)
			sections.forEach((section) => {
				if (section) {
					observer.observe(section)
				}
			})
		},
	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll)
		window.addEventListener("resize", this.updateIsMobile)
		this.checkSectionInView()
	},
	beforeDestroy() {
		window.removeEventListener("scroll", this.handleScroll)
		window.removeEventListener("resize", this.updateIsMobile)
	},
}
</script>
<style scoped>
@import url("https://fonts.cdnfonts.com/css/pp-neue-montreal");

@media (min-width: 1205px) {
	.bottom-nav {
		position: fixed;
		top: 24px;
		left: 24px;
		right: 24px;
		height: 91px;
		padding: 24px;
		box-sizing: border-box;
		background: rgba(174, 174, 174, 0.15);
		backdrop-filter: blur(10px);
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 25px;
		z-index: 100;
		font-family: "PP Neue Montreal", sans-serif;
		margin: 20px;
		transition: transform 0.3s, opacity 0.3s;
	}
}

.bottom-nav.hidden-on-desktop {
	transform: translateY(-100%);
	opacity: 0;
}

.nav-container {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.logo {
	flex: 1;
	font-size: 24px;
	color: black;
	text-align: left;
}

.nav-links {
	flex: 2;
	display: flex;
	justify-content: center;
}

.nav-item {
	gap: 34px;
	padding: 24px 24px;
	text-transform: uppercase;
	font-weight: 400;
	cursor: pointer;
	font-family: "PP Neue Montreal", sans-serif;
	font-size: 16px;
	color: black;
	background: transparent;
	transition: color 0.3s, transform 0.3s;
	border-radius: 20px;
}

.nav-item:hover {
	/* color: #677933; */
	transform: scale(1.1);

	color: white;
}

.nav-item.active {
	color: white;
}

.reserve-button {
	display: flex;
	padding: 10px 34px;
	background-color: transparent;
	border-radius: 20px;
	border: 1px black solid;
	font-size: 16px;
	color: black;
	cursor: pointer;
	text-transform: uppercase;
	text-align: right;
	transition: background-color 0.3s, transform 0.3s;
}
.logoImg {
	width: 210px;
}


.sagropImg {
	width: 125px;
	margin-bottom: 14px;
}
.reserve-button img {
	margin-left: 10px;
	margin-top: auto;
	margin-bottom: auto;
}

.reserve-button:hover {
	background-color: rgba(255, 255, 255, 0.5);
	transform: scale(1.05);
}

.dropdown-toggle {
	display: none;
	background: transparent;
	border: none;
	cursor: pointer;
	z-index: 120;
}

.menu-icon {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 30px;
	height: 30px;
	cursor: pointer;
	z-index: 120;
}

.menu-icon span {
	display: block;
	width: 100%;
	height: 4px;
	background-color: black;
	border-radius: 2px;
	transition: background-color 0.3s, transform 0.3s;
}

.menu-icon.open span:nth-child(1) {
	transform: rotate(49deg) translate(10px, 3px);
}

.menu-icon.open span:nth-child(2) {
	opacity: 0;
}

.menu-icon.open span:nth-child(3) {
	transform: rotate(-47deg) translate(10px, -5px);
}

.menu-icon span.white-span {
	background-color: white;
}

.dropdown-menu {
	position: fixed;
	width: 100vw;
	top: -44px;
	height: 110vh;
	background: rgba(0, 0, 0, 0.85);
	backdrop-filter: blur(10px);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	animation: fadeIn 0.3s;
	z-index: 101;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(-10%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.dropdown-item {
	padding: 20px;
	margin: 10px 0;
	width: 80%;
	text-align: center;
	font-size: 20px;
	color: white;
	border-bottom: 2px solid rgba(255, 255, 255, 0.2);
	cursor: pointer;
	text-transform: uppercase;
	transition: color 0.3s, transform 0.3s;
}

.dropdown-item:hover {
	color: #677933;
	transform: scale(1.05);
}

.dropdown-item.active {
	color: #677933;
	border-bottom: 2px solid #677933;
}

.reserve-button-dropdown {
	width: 100%;
	padding: 15px;
	margin-top: 20px;
	background-color: transparent;
	border: 2px white solid;
	border-radius: 30px;
	font-size: 20px;
	font-weight: 400;
	color: white;
	cursor: pointer;
	text-align: center;
	transition: background-color 0.3s, transform 0.3s, color 0.3s;
}

.reserve-button-dropdown img {
	margin-left: 10px;
	width: 12px;
}

.reserve-button-dropdown:hover {
	background-color: #677933;
	color: white;
	transform: scale(1.05);
}


@media (min-width: 1206px) and (max-width: 1380px) {
	.logoImg {
		width: 140px; /* Zmenší logoImg */
	}
	.sagropImg {
		width: 75px; /* Zmenší sagropImg */
		margin-bottom: 10px; /* Prispôsobí medzeru */
	}
}


@media (max-width: 1205px) {
	.nav-links,
	.reserve-button {
		display: none;
	}
	.logoImg {
		width: 160px;
	}
	.sagropImg {
		width: 85px;
		margin-bottom: 13px;
	}
	.logo {
		flex: 1;
		text-align: left;
		margin-left: -30px;
	}

	.bottom-nav {
		position: fixed;
		width: calc(100% - 40px); /* Reduce width with precise calculation */
		padding: 15px; /* Add padding inside the navbar */
		margin-left: 20px; /* Equal margin on both sides */
		margin-right: 20px; /* Equal margin on both sides */
		border-radius: 15px; /* Make corners rounded */
		top: 20px; /* Adjust position from the top */
		box-sizing: border-box;
		background: rgba(174, 174, 174, 0.15);
		backdrop-filter: blur(10px);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 100;
		font-family: "PP Neue Montreal", sans-serif;
		transition: transform 0.3s, opacity 0.3s;
	}
	.dropdown-toggle {
		display: block;
	}
}

@media (min-width: 1205px) {
	.dropdown-menu {
		display: none;
	}
}
</style>
