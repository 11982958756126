<template>
	<div class="faq-container">
		<div class="faq-header">
			<div>
				Pozrite si našu
				<span class="highlight"
					>FAQ <br />
					sekciu</span
				>
				pre všetky <br />
				informácie ktoré <br />
				potrebujete
			</div>
		</div>
		<div class="faq-items">
			<div v-for="(item, index) in faqItems" :key="index" class="faq-item" @click="toggleAnswer(index)" :class="{ open: item.open, animateOpen: item.animateOpen }" @transitionend="removeAnimation(index)">
				<div class="faq-question">
					{{ item.question }}
					<img :class="{ rotate: item.open, lowOpacity: item.open }" src="../assets/faq.svg" alt="icon" />
				</div>
				<div class="faq-answer" v-show="item.open">
					{{ item.answer }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			faqItems: [
				{
					question: "Aký je maximálny čas letu drona na jedno nabitie batérie?",
					answer: "DJI Agras T30 môže dosiahnuť maximálny čas letu približne 20 minút bez záťaže. Pri plnom zaťažení je však čas letu znížený na približne 7-9 minút v závislosti od konkrétnej konfigurácie",
					open: false,
					animateOpen: false,
				},
				{
					question: "Aký je maximálny dosah diaľkového ovládania drona?",
					answer: "Maximálny dosah diaľkového ovládania pre DJI Agras T30 je až 5 kilometrov za optimálnych podmienok bez rušenia",
					open: false,
					animateOpen: false,
				},
				{
					question: "Má dron funkcie vyhýbania sa prekážkam?",
					answer: "Áno, DJI Agras T30 je vybavený pokročilým systémom vyhýbania sa prekážkam. Používa všesmerový radar na detekciu prekážok až do vzdialenosti 15 metrov a môže začať obchádzať prekážky vo vzdialenosti približne 6 metrov. Má tiež radar zameraný nahor pre vyhýbanie sa prekážkam nad dronom",
					open: false,
					animateOpen: false,
				},
				{
					question: "Aká je prevádzková účinnosť modelu T30?",
					answer: "Dron dokáže postriekať priemerne 16,5 akrov za hodinu (pri dávke 4,8 l/aker, šírke postreku 5 m, rýchlosti 5 m/s a výške 2,5 m).",
					open: false,
					animateOpen: false,
				},
			],
		}
	},
	methods: {
		toggleAnswer(index) {
			this.faqItems[index].open = !this.faqItems[index].open
			this.faqItems[index].animateOpen = true
		},
		removeAnimation(index) {
			this.faqItems[index].animateOpen = false
		},
	},
}
</script>

<style scoped>
@keyframes rollOpen {
	0% {
		transform: rotateX(-90deg);
		opacity: 0;
	}
	100% {
		transform: rotateX(0deg);
		opacity: 1;
	}
}

.faq-container {
	display: flex;
	flex-wrap: wrap;
	padding: 20px;
	font-family: "PP Neue Montreal";
}
.faq-header {
	flex: 1;
	margin-right: 20px;
	font-size: 56px;
	font-weight: 500;
	line-height: 110%;
	letter-spacing: 0%;
	text-align: left;
}
.highlight {
	color: #6b8e23;
}
.faq-items {
	flex: 1;
	min-width: 300px;
}
.faq-item {
	margin: 12px 0;
	padding: 40px;
	border-radius: 20px;
	backdrop-filter: blur(24px);
	background: rgba(21, 21, 21, 0.03);
	cursor: pointer;
	position: relative;
	transition: all 0.7s ease;
}
.faq-item.open {
	box-shadow: 0px 40px 90px -10px rgba(0, 0, 0, 0.1);
	background: rgb(255, 255, 255);
}
.faq-item.animateOpen {
	animation: rollOpen 0.5s forwards;
}
.faq-question {
	color: rgb(21, 21, 21);
	font-size: 24px;
	font-weight: 500;
	line-height: 120%;
	text-align: left;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.faq-answer {
	color: rgb(21, 21, 21);
	font-size: 17px;
	font-weight: 400;
	line-height: 150%;
	margin-top: 10px;
	text-align: left;
	height: 0;
	opacity: 0;
	overflow: hidden;
	transition: height 0.7s ease, opacity 0.7s ease;
}
.faq-answer[style] {
	height: auto;
	opacity: 1;
}
img {
	/* position: absolute;
	top: 40px;
	right: 40px; */
	margin-left: 80px;
	margin-bottom: 20px;
	transition: transform 0.3s ease, opacity 0.3s ease;
}
.rotate {
	transform: rotate(180deg);
}
.lowOpacity {
	opacity: 0.5;
}
@media (max-width: 768px) {
	.faq-container {
		flex-direction: column;
	}
	.faq-header {
		margin-bottom: 32px;

		font-size: 32px;
		text-align: center;
	}
}
</style>
