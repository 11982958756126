<template>
	<section class="testimonials">
		<div class="content">
			<div class="testimonials-header">
				<h2>
					Skúsenosti<br />
					<span> a</span> referencie
				</h2>
				<p>
					Vybrané citáty od spokojných <br />
					poľnohospodárov alebo firemných klientov.
				</p>
				<button @click="scrollToReservation" class="submit-reservation desktop-only">ODOSLAŤ REZERVÁCIU <img alt="arrow" src="../assets/arrow.svg" /></button>
			</div>
			<div class="testimonials-slider">
				<Carousel :itemsToShow="1" :breakpoints="breakpoints" :wrap-around="computedWrapAround" v-model:currentSlide="currentPage" ref="carousel" @update:modelValue="handleSlideChange">
					<Slide v-for="(review, index) in reviews" :key="index">
						<div class="testimonial">
							<p class="quote">///</p>
							<p class="review">{{ review.text }}</p>
							<div class="author">
								<img :src="review.avatar" alt="Avatar autora" />
								<span>{{ review.author }}</span>
							</div>
						</div>
					</Slide>

					<template #addons>
						<Navigation />
						<Pagination />
					</template>
				</Carousel>
			</div>
		</div>
		<div class="pagination">
			<button @click="prevSlide" :disabled="currentPage === 0">&larr;</button>
			<button @click="nextSlide" :disabled="currentPage === totalPages - 1">&rarr;</button>
			<div class="page-numbers">
				<span class="page-number current">0{{ currentPage + 1 }}</span>
				<span class="page-number">/0{{ totalPages }}</span>
			</div>
		</div>
		<button @click="scrollToReservation" class="submit-reservation mobile-only">ODOSLAŤ REZERVÁCIU <img alt="arrow" src="../assets/arrow.svg" /></button>
	</section>
</template>

<script>
import { Carousel, Slide } from "vue3-carousel"
import "vue3-carousel/dist/carousel.css"
import Slider from "./Slider.vue"

export default {
	components: {
		Carousel,
		Slider,
		Slide,
	},
	data() {
		return {
			currentPage: 0,
			reviews: [
				{
					text: "Vynikajúca služba a podpora. Odporúčam každému, kto hľadá spoľahlivé riešenie.",
					author: "Jana Kováčová",
					avatar: require("../assets/pfpnorm.jpg"),
				},
				{
					text: "Tento produkt nám skutočne pomohol zefektívniť naše procesy a zvýšiť produktivitu.",
					author: "Marek Novák",
					avatar: require("../assets/pfpnorm.jpg"),
				},
				{
					text: "Služby tejto firmy sú bezkonkurenčné, skutočne sme spokojní.",
					author: "Petra Horváthová",
					avatar: require("../assets/pfpnorm.jpg"),
				},
				{
					text: "Služba, ktorá nám poskytla všetko, čo sme potrebovali a ešte viac.",
					author: "Martin Šimek",
					avatar: require("../assets/pfpnorm.jpg"),
				},

				// Add more reviews as needed
			],
			breakpoints: {
				1000: {
					itemsToShow: 3,
				},
			},
			isAnimating: false,
			windowWidth: window.innerWidth,
		}
	},
	computed: {
		totalPages() {
			return this.reviews.length
		},
		computedWrapAround() {
			return this.windowWidth >= 1000 ? true : false
		},
	},

	methods: {
		scrollToReservation() {
			const reservationSection = document.getElementById("reservation")
			if (reservationSection) {
				reservationSection.scrollIntoView({ behavior: "smooth" })
			}
		},

		handleSlideChange(currentSlide) {
			this.currentPage = currentSlide
			this.isAnimating = false // Reset the animation flag after slide change
		},
		updatePage(newPage) {
			if (newPage !== this.currentPage) {
				this.isAnimating = true
				this.$refs.carousel.slideTo(newPage)
			}
		},
		nextSlide() {
			if (!this.isAnimating && this.currentPage < this.totalPages - 1) {
				this.updatePage(this.currentPage + 1)
			}
		},
		prevSlide() {
			if (!this.isAnimating && this.currentPage > 0) {
				this.updatePage(this.currentPage - 1)
			}
		},
	},
}
</script>
<style>
.carousel__viewport {
	overflow: hidden;
}
.carousel__slide {
	overflow: hidden;
}
button{
	cursor: pointer;
}
</style>

<style scoped>
* {
	font-family: "PP Neue Montreal";
}

.testimonials {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 40px 0;
	overflow: hidden;
}

.content {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.testimonials-header {
	width: 50%;
	text-align: left;
	margin-bottom: 20px;
	margin-left: 20px;
}

.testimonials-header span {
	color: black;
}

.testimonials-header h2 {
	font-family: PP Neue Montreal;
	font-size: 56px;
	font-weight: 500;
	color: #6c8b3c;
}

.testimonials-header p {
	font-size: 18px;
	color: #141414;
}

.submit-reservation {
	position: static;
	transition: background-color 0.3s, transform 0.3s;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px 34px 10px 34px;
	border: none;
	border-radius: 999px;
	color: rgb(255, 255, 255);
	font-family: PP Neue Montreal;
	font-size: 16px;
	font-weight: 400;
	line-height: 140%;
	letter-spacing: 0%;
	text-align: center;
	text-transform: uppercase;
	background: rgb(103, 121, 51);
	/* Inside Auto Layout */
	flex: none;
	flex-grow: 0;
	margin: 16px 0px;
	transition: background-color 0.3s, transform 0.3s;
	margin-top: 175px;
}

.submit-reservation img {
	margin-left: 10px;
	margin-top: auto;
	margin-bottom: auto;
}

.submit-reservation:hover {
	background-color: #6c8b3c;
	transform: scale(1.05);
}

@media (min-width: 1000px) {
	.testimonials-slider {
		max-width: 60%;
		margin-left: 100px;
		flex-direction: column;
		justify-content: center;
		order: 1;
		align-items: flex-start;
		display: flex;
	}
}

.testimonial {
	background-color: #f0f0f0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	text-align: left;
	padding: 30px;
	min-height: 350px; /* Ensuring a minimum height */
	overflow: hidden;
	width: 100%; /* Ensuring full width */
	box-sizing: border-box;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 10px;
	box-sizing: border-box;
}

.testimonial .quote {
	font-size: 24px;
	color: #6c8b3c;
	margin: 0;
	margin-bottom: 20px;
	font-weight: bold;
}

.testimonial p {
	color: rgb(20, 20, 20);
	font-size: 18px;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: -2%;
	text-align: left;
	margin: 10px 0;
	flex-grow: 1;
}

.author {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.author img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin-right: 10px;
}

.author span {
	font-size: 14px;
	color: #333;
}

.current-page {
	font-size: 20px;
}

.pagination {
	display: flex;
	align-items: center;
	text-align: center;
}

.pagination button {
	background-color: transparent;
	box-sizing: border-box;
	border: 1px solid rgb(234, 234, 234);
	border-radius: 10px;
	padding: 5px 10px;
	margin: 0 20px;
	margin-bottom: 20px;
	width: 100px;
	height: 100px;
	cursor: pointer;
	transition: transform 0.3s, background-color 0.3s;
	color: black;
}

.pagination button:hover {
	transform: scale(1.1);
	background-color: #6c8b3c;
	color: white;
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
}

.page-number.current {
	font-size: 40px;
}

.page-number {
	font-size: 18px;
	align-self: end;
}

.page-numbers {
	display: flex;
	align-items: center;
	position: absolute;
	right: 20px;
}

.page-number.current {
	font-size: 40px;
	margin-right: 10px;
	color: #6c8b3c;
}

.page-number {
	font-size: 18px;
	color: #6c8b3c;
}

@media (max-width: 1000px) {
	.content {
		flex-direction: column;
		align-items: center;
	}

	.testimonial {
		width: 50%; /* Adjust width for mobile */
	}
	.testimonials-header {
		width: 100%;
		text-align: center;
		margin-left: 0;
	}
	.testimonials-header h2 {
		font-size: 36px;
	}
	.testimonials-header p {
		font-size: 16px;
		display: none;
	}
	.testimonials-slider {
		width: auto;
		margin-left: 0px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		display: flex;
	}
	.pagination button {
		display: none;
	}
	.page-number.current {
		font-size: 30px;
	}
	.page-number {
		font-size: 16px;
		margin-top: 80px;
	}
	.submit-reservation.desktop-only {
		display: none;
	}
	.submit-reservation.mobile-only {
		display: flex;
		margin-top: 20px;
		width: auto;
		text-align: center;
		justify-content: flex-start;
		margin-left: 16px;
		align-self: flex-start;
	}
}

@media (min-width: 1001px) {
	.submit-reservation.mobile-only {
		display: none;
	}
}
</style>
