<template>
	<div class="banner">
		<div class="text-content">
			<h1>
				Technológia dronov <br />
				Vysvetlená
			</h1>
			<p>
				Environmentálne výhody, adaptívne technológie <br />
				pre rôzne plodiny alebo podmienky
			</p>
		</div>
		<div class="details">
			<div class="detail-item">
				<span class="label">Dosah</span>
				<span class="value">4,500m </span>
			</div>
			<div class="detail-item">
				<span class="label">Kapacita nádrže</span>
				<span class="value">30 litrov</span>
			</div>
			<div class="detail-item">
				<span class="label">Doba letu</span>
				<span class="value">7-9 minút s plným zaťažením</span>
			</div>
			<div class="detail-item">
				<span class="label">Rýchlosť pokrytia</span>
				<span class="value">40 árov za hodinu</span>
			</div>
		</div>
	</div>
	<section class="features">
		<div class="feature-item" v-for="(feature, index) in features" :key="index">
			<div class="feature-index">[ {{ index + 1 }} ]</div>
			<div class="feature-content">
				<h3>{{ feature.title }}</h3>
				<p>{{ feature.description }}</p>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	data() {
		return {
			features: [
				{ title: "Presná aplikácia", description: "Znižuje chemické zaťaženie a zvyšuje účinnosť postreku." },
				{ title: "Úspora nákladov", description: "Znižuje potrebu manuálnej práce a množstvo použitých chemikálií." },
				{ title: "Univerzálnosť", description: "Vhodné pre rôzne druhy plodín a poľnohospodárske podmienky." },
				{ title: "Sférický radarový systém", description: "Odstránenie mŕtvych uhlov pomocou sférického radarového systému" },
			],
		}
	},
}
</script>

<style scoped>
* {
	font-family: "PP Neue Montreal";
}
.banner {
	position: relative;
	background-image: url("../assets/dronn2.png");
	background-size: cover;
	background-position: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-top: 260px;
	padding-bottom: 30px;
	border: none;
	border-radius: 20px;
	margin-left: 20px;
	margin-right: 20px;
	color: white;
	overflow: hidden; /* Ensure child elements don’t overflow */
}

.banner::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.25); /* Tmavá vrstva, 0.5 môžeš upraviť */
	z-index: 1; /* Uistí sa, že overlay je pod textom */
}

.banner * {
	position: relative;
	z-index: 2; /* Zabezpečí, že text bude nad overlayom */
}


.banner::after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 50%;
	background: linear-gradient(to bottom left, rgba(0, 0, 0, 0.5), transparent);
	z-index: 1; /* Ensure it appears above the background image */
	pointer-events: none; /* Ensure it doesn't block clicks */
	border-top-right-radius: 20px; /* Match the border-radius of the parent */
}

.banner > * {
	position: relative;
	z-index: 2; /* Ensure text and other elements appear above the overlay */
}

.text-content {
	max-width: 100%;
}
.text-content p {
	font-weight: 500;
}

h1 {
	font-size: 56px;
	margin: 0 0 10px;
	margin-left: 24px;
	font-weight: 500;
}
h3 {
	font-weight: 400;
	color: black;
}

p {
	font-size: 20px;
	margin: 0;
	margin-left: 24px;
}

.details {
	padding: 16px;
	border-radius: 10px;
	margin-top: 20px;
}

.detail-item {
	margin-bottom: 24px;
}

.label {
	display: block;
	margin-bottom: 16px;
	color: white;
	background: none;
	font-weight: 500;
}

.value {
	display: block;
	border: none;
	backdrop-filter: blur(120px);
	background: rgba(255, 255, 255, 0.15);
	border-radius: 13px;
	padding: 14px 24px 14px 24px;
}

.features {
	display: flex;

	flex-direction: column;
	margin-top: 50px;
	/* margin-left: 20px;

	margin-right: 20px; */
}

.feature-item {
	display: flex;
	align-items: flex-start;
	width: 100%;

	margin-bottom: 20px;
	max-width: 1200px;
}

.feature-index {
	font-size: 16px;
	color: #6c8b3c;
	min-width: 40px; /* Ensure minimum width to avoid stacking */
	margin-right: 10px;
}

.feature-content h3 {
	font-size: 20px;
	margin: 0 0 5px;
}

.feature-content p {
	margin: 0;
	color: rgb(69, 69, 69);
	font-family: PP Neue Montreal;
	font-size: 16px;
	font-weight: 400;
	line-height: 110%;
	letter-spacing: 0%;
	text-align: left;
}

@media (min-width: 1000px) {
	.text-content {
		max-width: 50%;
	}

	.details {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
	}

	.features {
		flex-direction: row;
		/* padding-left: 20px; */

		flex-wrap: wrap;
		justify-content: center;
	}

	.feature-item {
		width: 48%;
	}
}

@media (max-width: 1000px) {
	.features {
		margin-left: 20px;
		margin-right: 20px;
	}
}
@media (max-width: 900px) {
	.text-content h1 {
		color: rgb(255, 255, 255);
		font-family: PP Neue Montreal;
		font-size: 32px;
		font-weight: 500;
		margin-left: 16px;

		line-height: 110%;
		letter-spacing: 0%;
		text-align: left;
	}

	.text-content p {
		color: rgb(255, 255, 255);
		font-family: PP Neue Montreal;
		font-size: 18px;
		font-weight: 500;
		line-height: 110%;
		opacity: 0.7;

		margin-left: 16px;
		letter-spacing: 0%;
		text-align: left;
	}
}
@media (min-width: 1024px) {
	.feature-item {
		width: 23%;
	}
}
</style>
