<template>
	<div class="criteria-section">
		<div class="criteria-header">
			<div class="crit">KRITÉRIÁ</div>
			<div class="legend">
				<span><span class="dot drone-spraying"></span>POSTREK DRONOM</span>
				<span><span class="dot manual-application"></span>MANUÁLNA APLIKÁCIA</span>
			</div>
		</div>
		<div class="criteria-list">
			<div class="criteria-item" v-for="(item, index) in criteria" :key="index">
				<div class="criteria-name">{{ item.name }}</div>
				<div class="criteria-bars">
					<div class="bar-container">
						<div class="percentage green">{{ item.droneSpraying }}%</div>
						<div class="bar drone-spraying" :style="{ height: '8px', width: item.droneSpraying + 30 + 'mm' }"></div>
					</div>
					<div class="bar-container">
						<div class="percentage red">{{ item.manualApplication }}%</div>
						<div class="bar manual-application" :style="{ height: '8px', width: item.manualApplication + 30 + 'mm' }"></div>
					</div>
				</div>
				<div :class="['item-divider', { 'hidden-divider': index === criteria.length - 1 }]"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			criteria: [
				{ name: "Presnosť a efektívnosť", droneSpraying: 70, manualApplication: 30 },
				{ name: "Flexibilita", droneSpraying: 60, manualApplication: 40 },
				{ name: "Nákladová efektívnosť", droneSpraying: 70, manualApplication: 30 },
				{ name: "Výkonnosť a výkon", droneSpraying: 50, manualApplication: 50 },
				{ name: "Riziko operátora", droneSpraying: 70, manualApplication: 30 },
			],
		}
	},
}
</script>

<style scoped>
* {
	font-family: "PP Neue Montreal";
}

.criteria-section {
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
}

.criteria-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.criteria-header h2 {
	font-size: 24px;
	font-weight: bold;
}

.legend {
	display: flex;
	gap: 20px;
}

.legend span {
	display: flex;
	align-items: center;
	font-size: 14px;
	color: #333;
}

.legend .dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 5px;
	display: inline-block;
}

.legend .drone-spraying {
	background-color: #6c8b3c;
}

.legend .manual-application {
	background-color: #a03c3c;
}

.crit {
	color: rgb(21, 21, 21);
	font-family: PP Neue Montreal;
	font-size: 24px;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: 0%;
	text-align: left;
	text-transform: uppercase;
}

.criteria-list {
	display: flex;
	flex-direction: column;
}

.criteria-item {
	margin-bottom: 20px;
}

.criteria-name {
	font-size: 18px;
	margin-bottom: 10px;
}

.criteria-bars {
	display: flex;
	flex-direction: column;
}

.bar-container {
	position: relative;
	display: flex;
	margin-left: 50%;
	overflow: hidden;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 10px;
}
.percentage.green {
	color: rgb(103, 121, 51);
}

.percentage.red {
	color: rgb(176, 52, 52);
}
.bar {
	height: 10px;
	border-radius: 5px;
	position: relative;
	margin-top: 4px;
}

.drone-spraying {
	background: rgb(103, 121, 51);
}

.manual-application {
	background: rgb(176, 52, 52);
}

.percentage {
	font-size: 14px;
	color: #333;
	margin-bottom: 4px;
}

.item-divider {
	margin-top: 10px;
	border: 1px solid rgb(152, 152, 152);
	opacity: 0.15;
}

.hidden-divider {
	display: none;
}

/* Responsive styles */
@media (max-width: 1000px) {
	.bar-container {
		position: relative;
		display: flex;
		margin-left: 0;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 10px;
	}
	* {
		font-weight: 500;
	}
	.criteria-header {
		flex-direction: column;
		align-items: flex-start;
	}

	.legend {
		margin-top: 24px;
		margin-bottom: 20px;
		flex-wrap: wrap;
	}

	.criteria-name {
		font-size: 16px;
	}

	.percentage {
		font-size: 12px;
	}
}
</style>
