<template>
	<nav></nav>
	<router-view />
</template>

<style>
body,
html {
	margin: 0;
	padding: 0;
	height: 100%;
}
</style>
