<template>
	<Carousel>
	  <Slide v-for="(review, index) in reviews" :key="index">
		<div class="carousel__item">
		  <p>{{ review.text }}</p>
		  <p><strong>- {{ review.author }}</strong></p>
		  <!-- Uncomment the line below if you want to include avatars -->
		  <!-- <img :src="review.avatar" alt="Avatar" /> -->
		</div>
	  </Slide>

	  <template #addons>
		<Navigation />
		<Pagination />
	  </template>
	</Carousel>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
	name: 'ReviewCarousel',
	components: {
	  Carousel,
	  Slide,
	  Pagination,
	  Navigation,
	},
	data() {
	  return {
		reviews: [
		  {
			text: "Tento výber sme vytvorili špeciálne pre dizajnérov, aby sme vám uľahčili a urýchlili tvorbu nádherných neštandardných kompozícií pre vašich zákazníkov.",
			author: "Dodo Titarenko",
			// avatar: "/assets/profil.svg",
		  },
		  {
			text: "Tento výber sme vytvorili špeciálne pre dizajnérov, aby sme vám uľahčili a urýchlili tvorbu nádherných neštandardných kompozícií pre vašich zákazníkov.",
			author: "TT Titarenko",
			// avatar: "/assets/profil.svg",
		  },
		  {
			text: "Tento výber sme vytvorili špeciálne pre dizajnérov, aby sme vám uľahčili a urýchlili tvorbu nádherných neštandardných kompozícií pre vašich zákazníkov.",
			author: "Jo Titarenko",
			// avatar: "/assets/profil.svg",
		  },
		  {
			text: "Tento výber sme vytvorili špeciálne pre dizajnérov, aby sme vám uľahčili a urýchlili tvorbu nádherných neštandardných kompozícií pre vašich zákazníkov.",
			author: "VlSAad Titarenko",
			// avatar: "/assets/profil.svg",
		  },
		  {
			text: "Tento výber sme vytvorili špeciálne pre dizajnérov, aby sme vám uľahčili a urýchlili tvorbu nádherných neštandardných kompozícií pre vašich zákazníkov.",
			author: "Vlad Titarenko",
			// avatar: "/assets/profil.svg",
		  },
		  // Add more reviews as needed
		],
	  };
	},
})
</script>

<style>
.carousel__item {
	min-height: 200px;
	width: 100%;
	background-color: var(--vc-clr-primary);
	color: var(--vc-clr-white);
	font-size: 20px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	text-align: center;
  }
  
  .carousel__slide {
	padding: 10px;
  }
  
  .carousel__prev,
  .carousel__next {
	box-sizing: content-box;
	border: 5px solid white;
  }
</style>
