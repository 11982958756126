<template>
	<div class="blend">
		<Nav></Nav>
		<div id="home">
			<div class="home">
				<img class="home-image" alt="Obrazok Drona" src="../assets/dronn.png" />
				<h1 class="header-text">Revolúcia v poľnohospodárstve s presným postrekom pomocou dronu</h1>
				<div class="box-right">
					<p>Efektívne, presné a ekologické riešenia prispôsobené vašim poľnohospodárskym potrebám</p>
					<button @click="scrollToReservation" class="reserve-button">Rezervovať <img alt="arrow" src="../assets/arrow-black.svg" /></button>
				</div>
			</div>
		</div>
		<div id="reservation">
			<section class="short-text">
				<p>
					Rezervujte si službu <span class="highlight">postrekovania dronom</span> <br />
					
				</p>
			</section>
			<FarmReservation></FarmReservation>
		</div>
		<div id="about">
			<section class="short-text dronoch">
				<p>
					Zistite viac o <br />
					<span class="highlight">našich dronoch</span>
				</p>
			</section>

			<BannerDrone />
		</div>
		<div id="permissions">
			<Reviews />
			<Advantages />
			<Criteria />
		</div>

		<div id="faq">
			<Faq />
		</div>
		<div id="contact">
			<Form />
		</div>
	</div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"
import Nav from "@/components/Nav.vue"
import FarmReservation from "@/components/FarmReservation.vue"
import BannerDrone from "@/components/Banner.vue"
import Reviews from "@/components/Reviews.vue"
import Advantages from "@/components/Advantages.vue"
import Criteria from "@/components/Criteria.vue"
import Faq from "@/components/Faq.vue"
import Form from "@/components/Form.vue"

@Options({
	components: {
		Nav,
		FarmReservation,
		BannerDrone,
		Reviews,
		Form,
		Faq,
		Criteria,
		Advantages,
	},
	methods: {
		scrollToReservation() {
			const reservationSection = document.getElementById("reservation")
			if (reservationSection) {
				reservationSection.scrollIntoView({ behavior: "smooth" })
			}
		},
	},
})
export default class HomeView extends Vue {}
</script>

<style scoped>
@media (max-width: 1000px) {
	.home {
		position: relative;
		margin: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}
	.home-image {
		width: 100%;
		height: 100vh;
		border-radius: 20px;
		object-fit: cover;
		filter: brightness(86%);
		z-index: -1;
	}

	.header-text {
		position: absolute;
		color: rgb(255, 255, 255);
		font-family: PP Neue Montreal;
		font-size: 32px;
		font-weight: 500;
		line-height: 110%;
		letter-spacing: 0%;
		text-align: left;
		width: auto;
		bottom: 170px;
		letter-spacing: 0%;
		left: 16px;
		margin-right: 16px;
		text-align: left;
	}
	.box-right {
		position: absolute;
		bottom: 10%;
		left: 16px;
		margin-right: 16px;

		display: flex;
		flex-direction: column;
		align-items: left;
		text-align: left;
	}
	.box-right p {
		color: rgb(255, 255, 255);
		font-family: PP Neue Montreal;
		font-size: 18px;
		font-weight: 500;
		line-height: 110%;
		letter-spacing: 0%;
		text-align: left;
		font-weight: 500;

		line-height: 110%;
		letter-spacing: 0%;
	}
	.reserve-button {
		display: flex;
		position: absolute;
		bottom: -40px;
		padding: 12px 14px;
		background-color: white; /* Semi-transparent background for the button */
		border-radius: 20px;
		border: none;
		font-size: 14px;
		color: black;
		cursor: pointer;
		text-transform: uppercase;

		transition: background-color 0.3s, transform 0.3s;
	}

	.reserve-button img {
		margin-top: auto;
		margin-bottom: auto;
		margin-right: 0px;
		margin-left: 10px;
		z-index: 10;
		border-radius: 0px;
		border: none;
		width: 10px;
		height: 12px;
	}

	.reserve-button:hover {
		background-color: white;
		transform: scale(1.03);
	}
	.short-text p {
		font-family: PP Neue Montreal;
		font-size: 32px;
		font-weight: 500;
		line-height: 110%;
		letter-spacing: 0%;

		text-align: center;
	}
	.highlight {
		color: #677933;
	}
	.short-text {
		left: 368px;
		width: auto;
		right: 368px;
	}
}

@media (min-width: 1000px) {
	.home {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.home-image {
		margin: 20px;
		width: 100%;
		height: 95vh;
		object-fit: cover;
		border-radius: 20px;
		z-index: -1;
		filter: brightness(70%);
	}

	.header-text {
		position: absolute;
		bottom: 0px;
		color: rgb(255, 255, 255);
		font-family: PP Neue Montreal;
		font-size: 64px;
		font-weight: 500;
		line-height: 110%;
		width: 669px;
		letter-spacing: 0%;
		text-align: left;
		left: 44px;
	}
	.box-right {
		position: absolute;
		bottom: 44px;
		right: 44px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.box-right p {
		color: rgb(255, 255, 255);
		font-family: PP Neue Montreal;
		font-size: 20px;
		font-weight: 500;
		width: 470px;
		line-height: 110%;
		letter-spacing: 0%;
		text-align: left;
	}
	.reserve-button {
		display: flex;
		padding: 10px 34px;
		background-color: white;
		border-radius: 20px;
		border: none;
		font-size: 16px;
		color: black;
		cursor: pointer;
		text-transform: uppercase;
		transition: background-color 0.3s, transform 0.3s;
		margin-top: 20px;
	}
	.reserve-button img {
		margin-left: 10px;
		margin-top: 2px;
		width: 10px;
		height: 14px;
	}
	.reserve-button:hover {
		background-color: white;
		transform: scale(1.03);
	}
	.short-text p {
		font-family: PP Neue Montreal;
		font-size: 56px;
		font-weight: 500;
		line-height: 110%;
		letter-spacing: 0%;
		text-align: center;
	}
	.highlight {
		color: #677933;
	}
	.short-text {
		left: 368px;
		width: auto;
		right: 368px;
	}
}

@media (max-width: 1200px) and (min-width: 1000px) {
	.header-text {
		position: absolute;
		bottom: 160px;
		color: rgb(255, 255, 255);
		font-family: PP Neue Montreal;
		font-size: 44px;
		font-weight: 500;
		line-height: 110%;
		width: 669px;
		letter-spacing: 0%;
		text-align: left;
		left: 44px;
	}
	.box-right {
		position: absolute;
		bottom: 44px;
		right: 44px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.box-right p {
		color: rgb(255, 255, 255);
		font-family: PP Neue Montreal;
		font-size: 20px;
		font-weight: 500;
		width: 470px;
		line-height: 110%;
		letter-spacing: 0%;
		text-align: left;
	}
}
</style>
