<template>
	<div>
		<div class="contact-header">
			<div class="head mobile">
				Kontaktujte  <br /><span class="highlight">nás</span>
				
			</div>
		</div>
		<div class="contact-container">
			<form class="contact-form" @submit.prevent="submitForm">
				<div class="form-row">
					<input type="text" placeholder="Celé meno" v-model="form.fullName" class="input-field form-input" required />
					<input type="email" placeholder="Email" v-model="form.email" class="input-field form-input" required />
					<input type="text" placeholder="Mobil" v-model="form.phone" class="input-field form-input" required />
				</div>
				<textarea placeholder="Správa" v-model="form.message" class="textarea-field form-input" required></textarea>
				<button class="send-button" type="submit">ODOSLAŤ <img src="../assets/arrow.svg" alt="Send" /></button>
			</form>
			<div class="contact-header">
				<div class="head desktop">
					Kontaktujte  <br />
					<span class="highlight">nás</span>
				</div>
				<div class="contact-details">
					<div class="contact-detail">
						<label for="address">Adresa</label>
						<div class="under_label">Hlavná 14/59 ,Šaľa, Slovakia</div>
					</div>
					<div class="contact-row">
						<div class="contact-detail">
							<label for="mobile">Mobil</label>
							<div class="under_label">0911 339 939</div>
						</div>
						<div class="contact-detail">
							<label for="email">Email</label>
							<div class="under_label">lukas.psota@sagrop.sk</div>
						</div>
					</div>
					<div class="social-icons">
						<a href="https://www.facebook.com/Sagropspol/"><img src="../assets/fcWhite.svg" alt="Facebook" /></a>
						<a href="https://www.instagram.com/sagrop_sala/"><img src="../assets/igWhite.svg" alt="Instagram" /></a>
						<a href="https://www.youtube.com/channel/UCvk4O6e8NzRZzio6c8MWv1A"><img src="../assets/ytWhite.svg" alt="YouTube" /></a>
					</div>
				</div>
			</div>
		</div>
		<footer class="footer">
			<a href=""> <img class="logoImg" src="../assets/file.png" alt="logo" /></a>

			<div class="footer-content">
				<nav>
					<a href="#" @click.prevent="scrollToSection('home')">DOMOV</a>
					<a href="#" @click.prevent="scrollToSection('reservation')">REZERVÁCIA</a>
					<a href="#" @click.prevent="scrollToSection('about')">O NÁS</a>
					<a href="#" @click.prevent="scrollToSection('permissions')">OSVEDČENIA</a>
					<a href="#" @click.prevent="scrollToSection('faq')">FAQ</a>
					<a href="#" @click.prevent="scrollToSection('contact')">KONTAKT</a>
				</nav>
				<div class="footer-social-icons">
					<a href="https://www.facebook.com/Sagropspol/"><img src="../assets/facebook2.svg" alt="Facebook" /></a>
					<a href="https://www.instagram.com/sagrop_sala/"><img src="../assets/instagram2.svg" alt="Instagram" /></a>
					<a href="https://www.youtube.com/channel/UCvk4O6e8NzRZzio6c8MWv1A"><img src="../assets/youtube2.svg" alt="YouTube" /></a>

				</div>
			</div>
			<hr />
			<div class="footer-bottom">
				<p>Zásady ochrany osobných údajov</p>
				<p>Podmienky služby</p>
				<p>Autorské práva</p>
			</div>

			<div class="copyright">
				<a class="ofc" href="https://www.sagrop.sk/" title="Sagrop – Poľnohospodárske služby"> Copyright &copy; 2024 Sagrop All Rights reserved</a>
				<div class="center">
					Made with
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
						<path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
					</svg>
					by <a class="cp" href="http://acronym.sk/"> Acronym.sk</a>
				</div>
			</div>
		</footer>
		<transition name="fade">
			<div v-if="isAlertVisible" class="alert">
				<p>Správa bola úspešne odoslaná!</p>
			</div>
		</transition>
	</div>
</template>

<script>
import emailjs from "emailjs-com"

export default {
	name: "ContactForm",
	data() {
		return {
			form: {
				fullName: "",
				email: "",
				phone: "",
				message: "",
			},
			isAlertVisible: false,
		}
	},
	methods: {
		submitForm() {
			const templateParams = {
				fullName: this.form.fullName,
				email: this.form.email,
				phone: this.form.phone,
				message: this.form.message,
			}

			console.log("Sending templateParams:", templateParams)

			emailjs
				.send(
					"service_rsir8pq", //rovnake
					"template_smece3o", //u kazdeho noveho ine
					templateParams,
					"T_y0dwmCOA4DyTETq" //rovnake
				)
				.then(
					(response) => {
						console.log("Email successfully sent!", response.status, response.text)
						this.showAlert()
					},
					(error) => {
						console.error("Failed to send message:", error)
						alert("Nepodarilo sa odoslať správu. Skúste to znova.")
					}
				)
		},
		showAlert() {
			this.isAlertVisible = true
			setTimeout(() => {
				this.isAlertVisible = false
			}, 3000)
		},
		scrollToSection(sectionId) {
			const section = document.getElementById(sectionId)
			if (section) {
				section.scrollIntoView({ behavior: "smooth" })
			}
		},
	},
}
</script>

<style scoped>
* {
	font-family: PP Neue Montreal;
}
hr {
	/* Blue/400 */
	border: 1px solid rgb(158, 164, 179);
	opacity: 0.2;
}
.contact-container {
	display: flex;
	justify-content: space-between;
	padding: 40px;
	flex-wrap: wrap;
}
.contact-header {
	flex: 1;
	margin-right: 40px;
}

.sagrop {
	opacity: 70%;
	color: white;
	text-decoration: none;
}

.ofc {
	color: white;
}
.head {
	font-family: PP Neue Montreal;
	font-size: 56px;
	font-weight: 500;
	line-height: 110%;
	margin-bottom: 89px;
	letter-spacing: 0%;
	text-align: left;
}
.highlight {
	color: #6b8e23;
}
.contact-details {
	display: flex;
	flex-direction: column;
}
.contact-row {
	display: flex;
	margin-top: 50px;
	gap: 50px;
}
.contact-detail {
	margin-bottom: 20px;
}
.contact-detail label {
	display: block;
	margin-bottom: 14px;
	color: rgb(69, 69, 69);
	font-family: PP Neue Montreal;
	font-size: 18px;
	font-weight: 500;
	line-height: 139%;
	letter-spacing: -2%;
	text-align: left;
}
.input-field {
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #ccc;
	width: 100%;
	box-sizing: border-box;
	background-color: #f1f1f1;
}
.social-icons {
	display: flex;
	gap: 10px;
	margin-top: 20px;
}
.contact-form {
	flex: 1;

	display: flex;
	flex-direction: column;
	/* margin-right: 56px; */
	gap: 14px;
	order: 1; /* Ensure the form is displayed first on mobile */
}
.form-row {
	display: flex;
	gap: 14px;
}
@media (min-width: 1000px) {
	.form-input {
		flex: 1;
		padding: 15px;
		border-radius: 14px;
		border: none;
		padding-left: 27px;
		background: rgb(244, 244, 244);
	}
	.head.mobile {
		display: none;
	}
	.logoImg {
		width: 280px;
		filter: invert();
	}
}

.textarea-field {
	height: 150px;
	resize: none;
}
.send-button {
	background-color: #6b8e23;
	color: white;
	padding: 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	transition: background-color 0.3s, transform 0.3s;
	flex: none;
	flex-grow: 0;

	align-items: center;
	border-radius: 999px;
	border: none;
	background: rgb(103, 121, 51);
	padding: 10px 34px 10px 34px;
}

.send-button img {
	margin-left: 10px;
	margin-top: auto;
	margin-bottom: auto;
}

.send-button:hover {
	background-color: #6c8b3c;
	transform: scale(1.01);
}

.under_label {
	color: rgb(34, 40, 43);
	font-family: PP Neue Montreal;
	font-size: 18px;
	font-weight: 400;
	line-height: 129%;
	letter-spacing: -2%;
	text-align: left;
	opacity: 0.6;
}
.footer {
	background: rgb(20, 20, 20);
	color: #fff;
	padding: 40px;
	text-align: center;
	margin-top: 40px;
}
.footer-content {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.footer-content nav {
	display: flex;
	gap: 20px;
	margin: 20px 0;
	flex-wrap: wrap;
	justify-content: center;
	color: rgb(244, 244, 244);
	font-family: PP Neue Montreal;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: left;
	text-transform: uppercase;
}
.footer-content nav a {
	color: #fff;
	text-decoration: none;
}

.footer-social-icons {
	display: flex;
	gap: 10px;
	margin-top: 56px;
	margin-bottom: 29.5px;
	flex-wrap: wrap;
	justify-content: center;
}
.footer-bottom {
	display: flex;
	justify-content: center;
	gap: 20px;
	opacity: 0.3;
	color: rgb(244, 244, 244);
	font-family: PP Neue Montreal;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0px;
	text-align: left;
	margin-top: 20px;
}
@media (max-width: 1000px) {
	.contact-container {
		flex-direction: column;
		padding: 20px;
	}
	.logoImg {
		width: 200px;

		filter: invert();
	}
	.footer-bottom {
		color: rgb(244, 244, 244);
		font-family: PP Neue Montreal;
		font-size: 14px;
		font-weight: 400;
		line-height: 26px;
		letter-spacing: 0px;
		text-align: left;
	}
	.send-button {
		background-color: #6b8e23;
		color: white;
		padding: 15px;
		margin-bottom: 36px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-radius: 999px;
		border: none;
		background: rgb(103, 121, 51);
		padding: 10px 34px 10px 34px;
	}
	.head.mobile {
		padding: 0;
		margin: 0;
		font-family: PP Neue Montreal;
		font-size: 32px;
		font-weight: 500;
		line-height: 110%;
		letter-spacing: 0%;
		text-align: center;
	}
	.contact-detail {
		margin-bottom: 0px;
	}
	.head.desktop {
		display: none;
	}
	.contact-header {
		margin-right: 0;
		order: 2; /* Ensure the header is displayed second on mobile */
	}
	.contact-header h1 {
		font-size: 32px;
		text-align: center;
	}
	.contact-form {
		margin-left: 0;
		margin-top: 20px;
		order: 1; /* Ensure the form is displayed first on mobile */
	}
	.form-input {
		padding: 15px;
		border-radius: 14px;
		border: none;
		padding-left: 27px;
		background: rgb(244, 244, 244);
	}
	.form-row,
	.contact-row {
		flex-direction: column;
	}
	.footer-content {
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}
	.footer-content h2 {
		flex-direction: column;
		margin-bottom: 400px;
		position: absolute;
	}
	.footer-content nav {
		flex-direction: column;
		align-items: flex-start;
		gap: 32px;
		padding-top: 20px;
	}
	.footer-social-icons {
		gap: 29px;
		flex-direction: column;
		align-items: flex-end;
	}
	.textarea-field {
		height: 270px; /* Ensure the height is consistent with the desktop */
		resize: none;
	}
}

.alert {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #67a51e;
	color: white;
	padding: 20px 40px;
	text-align: center;
	border-radius: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	z-index: 1000;
	opacity: 1;
	transition: opacity 0.5s ease;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
@media (max-width: 1000px) {
	.copyright .center {
		margin-top: 24px;
	}
	.copyright {
		padding-left: 0cm;
		font-size: 13px;
		font-weight: 400;
		width: 96%;
		height: 1cm;
		margin: 0 auto;
		padding-top: 1cm;
		margin-bottom: 1cm;
	}
	.copyright svg {
		height: 11px;
		width: 13px;
	}
	.copyright a {
		text-decoration: none;
	}
	.cp {
		color: #ff78fe;
	}
	.cp a {
		color: #ff78fe;
		text-decoration: none;
	}
}
@media (min-width: 1000px) {
	.copyright .center {
		text-align: center;
		margin-top: 20px;
	}

	.copyright {
		padding-left: 0cm;
		font-size: 14px;
		font-weight: 400;
		width: 100%;
		height: 1cm;
		margin: 0 auto;
		padding-top: 1cm;
	}
	.cp {
		color: #ff78fe;
	}
	.copyright svg {
		height: 11px;
		width: 13px;
	}
	a {
		text-decoration: none;
	}
	.cp a {
		color: #ff78fe;
		text-decoration: none;
	}
	.acronym {
		padding-left: 0cm;
		font-size: 14px;
		font-weight: 100;
		width: 96%;

		margin: 0 auto;
	}
}
</style>
